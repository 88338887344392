const initialState = {
  loading: false,
  shutters: [],
  shutterSelected: [],
  isUploaded: null,
  contributors: [],
  link: null,
  session: null,
  progress: [],
  planned_at: null,
  reminder: null,
  isSelectedToShare: false,
  loadedcontributors: [],
  nextReminder: null,
  immediately: null,
  checkingBack: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    //endpoints
    case "GET_SHUTTERS":
      return {
        ...state,
        loading: true,
        isUploaded: null,
        contributors: [],
        shutterSelected: [],
        checkingBack: true,
      };
    case "GET_SHUTTERS_SUCCESS":
      return {
        ...state,
        loading: false,
        shutters: action.payload,
      };
    case "GET_SHUTTERS_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "UPLOAD_FILE":
      return {
        ...state,
        loading: true,
        isUploaded: null,
        contributors: [],
      };
    case "UPLOAD_FILE_SUCCESS":
      return {
        ...state,
        loading: false,
        isUploaded: true,
        contributors: action.payload,
      };
    case "UPLOAD_FILE_SCHEMA_FAILED":
      return {
        ...state,
        loading: false,
        isUploaded: false,
      };
    case "UPLOAD_FILE_FAILED":
      return {
        ...state,
        loading: false,
        isUploaded: null,
      };
    case "GENERATE_LINK":
      return {
        ...state,
        loading: true,
      };
    case "GENERATE_LINK_SUCCESS":
      return {
        ...state,
        loading: false,
        link: action.payload.link,
        session: action.payload.id,
        planned_at: new Date(),
        reminder: null,
      };
    case "GENERATE_LINK_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "SHARE_SURVEYS":
      return {
        ...state,
        loading: true,
        progress: [],
      };
    case "SHARE_SURVEYS_SUCCESS":
      return {
        ...state,
        loading: false,
        progress: action.payload.progress,
        shutterSelected: [],
        shutters: [],
        checkingBack: false,
      };
    case "SHARE_SURVEYS_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_SURVEY_REMINDER":
      return {
        ...state,
        loading: true,
        reminder: null,
      };
    case "UPDATE_SURVEY_REMINDER_SUCCESS":
      return {
        ...state,
        loading: false,
        reminder: action.payload,
      };
    case "UPDATE_SURVEY_REMINDER_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_SURVEY_PLANNING":
      return {
        ...state,
        loading: true,
        planned_at: null,
      };
    case "UPDATE_SURVEY_PLANNING_SUCCESS":
      return {
        ...state,
        loading: false,
        planned_at: action.payload,
      };
    case "UPDATE_SURVEY_PLANNING_FAILED":
      return {
        ...state,
        loading: false,
      };

    case "LOAD_SHARED_CONTRIBUTORS":
      return {
        ...state,
        loading: true,
        progress: [],
        loadedcontributors: [],
        nextReminder: null,
      };
    case "LOAD_SHARED_CONTRIBUTORS_SUCCESS":
      return {
        ...state,
        loading: false,
        loadedcontributors: action.payload.contributors,
        progress: action.payload.progress,
        nextReminder: action.payload.nextReminder,
      };
    case "LOAD_SHARED_CONTRIBUTORS_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "UPDATE_SURVEY_IMMEDYATLY":
      return {
        ...state,
        loading: true,
        immediately: null,
      };
    case "UPDATE_SURVEY_IMMEDYATLY_SUCCESS":
      return {
        ...state,
        loading: false,
        immediately: action.payload,
      };
    case "UPDATE_SURVEY_IMMEDYATLY_FAILED":
      return {
        ...state,
        loading: false,
      };

    //simple actions

    case "SELECT_SHUTTERS":
      return {
        ...state,
        shutterSelected: action.payload,
      };
    case "UPDATE_CONTRIBUTOR_SHUTTER":
      return {
        ...state,
        loading: false,
        contributors: action.payload,
      };
    case "REMOVE_CONTRIBUTOR":
      return {
        ...state,
        loading: false,
        contributors: action.payload,
      };

    case "ADD_CONTRIBUTOR":
      return {
        ...state,
        loading: false,
        contributors: [...state.contributors, action.payload],
      };
    case "ADD_CONTRIBUTOR_LOADING":
      return {
        ...state,
        loading: false,
        contributors: [],
      };
    case "SET_UPLOAD_TRUE":
      return {
        ...state,
        isUploaded: action.payload,
      };
    case "SELECT_CIRCLE_MANAGEMENT":
      return {
        ...state,
        loading: false,
        contributors: action.payload,
      };

    case "SELECT_PLANNED_AT":
      return {
        ...state,
        loading: false,
        planned_at: action.payload,
      };
    case "SELECT_REMINDER":
      return {
        ...state,
        loading: false,
        reminder: action.payload,
      };
    case "SELECT_CONTRIBUTORS_SHARING":
      return {
        ...state,
        loading: false,
        isSelectedToShare: action.payload,
      };

    default:
      return state;
  }
};
