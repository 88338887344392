/* eslint-disable react/prop-types */
import React, { useState } from "react";

export const LocaleContext = React.createContext();

export const LocaleContextProvider = (props) => {
  const [locale, setLocale] = useState("fr");

  return (
    <LocaleContext.Provider value={[locale, setLocale]}>
      {props.children}
    </LocaleContext.Provider>
  );
};
