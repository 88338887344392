const initialState = {
  isLoggedIn: false,
  loading: false,
  user: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "LOGIN_USER":
      return {
        ...state,
        isLoggedIn: false,
        loading: true,
      };
    case "LOGIN_USER_SUCCESS":
      return {
        ...state,
        isLoggedIn: true,
        loading: false,
        user: action.payload.user,
      };
    case "LOGIN_USER_FAILED":
      return {
        ...state,
        isLoggedIn: false,
        loading: false,
      };
    default:
      return state;
  }
};
