import React, { memo } from "react";
import Loading from "components/Loading";
import { Container } from "./styles";

const LoadingLayout = () => {
  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default memo(LoadingLayout);
