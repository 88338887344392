/* eslint-disable react/display-name */
import React, { Suspense, lazy, useContext } from "react";
import history from "helpers/history";
import { Router, Switch, Route, useLocation } from "react-router-dom";
import Layout from "components/Layout";
import LoadingLayout from "components/LoadingLayout";
import Redirection from "helpers/Redirection";
import { IntlProvider } from "react-intl";
import { LocaleContext } from "helpers/LocaleContext";
import fr from "translations/fr";
import en from "translations/en";

const Login = lazy(() => import("features/Login"));
const Dashboard = lazy(() => import("features/Dashboard"));
const Analysis = lazy(() => import("features/Analysis"));
const QA = lazy(() => import("features/QA"));
const KPI = lazy(() => import("features/KPI"));
const Rapport = () => <h3>Rapport page</h3>;
const Contributors = () => <h3>Contributors page</h3>;
const Settings = () => <h3>Settings page</h3>;
const NoMatchPage = () => <h3>404 - Not found</h3>;

const messages = {
  en,
  fr,
};

export default () => {
  let location = useLocation();
  const { pathname } = location;
  localStorage.setItem("link", pathname);

  const [locale] = useContext(LocaleContext);
  return (
    <IntlProvider locale={locale} messages={messages[locale]}>
      <Router history={history}>
        <Switch>
          <Suspense fallback={<LoadingLayout />}>
            <Route exact path="/" component={Login} />
            <Redirection>
              <Switch>
                <Layout>
                  <Route exact path="/analysis/:id" component={Analysis} />
                  <Route exact path="/analysis" component={Analysis} />
                  <Route exact path="/dashboard" component={Dashboard} />
                  <Route exact path="/analysis-kpi" component={KPI} />
                  <Route exact path="/rapport" component={Rapport} />
                  <Route exact path="/contributors" component={Contributors} />
                  <Route exact path="/settings" component={Settings} />
                  <Route
                    exact
                    path="/q/:link"
                    render={({ match }) => <QA link={match.params.link} />}
                  />
                </Layout>
                <Route path="*" component={NoMatchPage} />
              </Switch>
            </Redirection>
          </Suspense>
        </Switch>
      </Router>
    </IntlProvider>
  );
};
