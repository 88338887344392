import { createGlobalStyle } from "styled-components/macro";
import Gilory from "assets/fonts/Gilroy.woff";
import GiloryBold from "assets/fonts/Gilroy-Bold.woff";
import Medium from "assets/fonts/Inter-Medium.woff";
import InterBold from "assets/fonts/Inter-Bold.woff";
import Inter from "assets/fonts/Inter-Regular.woff";
import GiloryBold2 from "assets/fonts/Gilroy-Bold.woff2";
import Gilory2 from "assets/fonts/Gilroy.woff2";
import Medium2 from "assets/fonts/Inter-Medium.woff2";
import InterBold2 from "assets/fonts/Inter-Bold.woff2";
import Inter2 from "assets/fonts/Inter-Regular.woff2";
const Theme = createGlobalStyle`
  @import url('https://fonts.googleapis.com/icon?family=Material+Icons');
  @font-face {
    font-family: 'Gilory Bold';
    src: local('Gilory Bold'), local('GiloryBold'),
    url(${GiloryBold2}) format('woff2'),
    url(${GiloryBold}) format('woff');
}
@font-face {
  font-family: 'Gilory';
  src: local('Gilory'), local('Gilory'),
  url(${Gilory2}) format('woff2'),
  url(${Gilory}) format('woff');
}

@font-face {
  font-family: 'Inter Medium';
  src: local('Inter Medium'), local('InterMedium'),
  url(${Medium2}) format('woff2'),
  url(${Medium}) format('woff');
}

@font-face {
  font-family: 'Inter Bold';
  src: local('Inter Bold'), local('InterBold'),
  url(${InterBold2}) format('woff2'),
  url(${InterBold}) format('woff');
}
 
@font-face {
  font-family: 'Inter';
  src: local('Inter'), local('Inter'),
  url(${Inter2}) format('woff2'),
  url(${Inter}) format('woff');
}

  html {
    body {
      margin: 0;
      padding: 0;
    }
    a {
      text-decoration: none;
    }
  }
`;

export default Theme;
