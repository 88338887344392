const initialState = {
  isCompleted: false,
  loading: false,
  qa_script: [],
  qa_shutter: [],
  selectedAnswers: [],
  survey: null,
  analysis: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "GET_QA_SCRIPT_LOADING":
      return {
        ...state,
        isCompleted: false,
        loading: true,
        qa_shutter: [],
        qa_script: [],
        survey: null,
        analysis: null,
      };
    case "GET_QA_SCRIPT_SUCCESS":
      return {
        ...state,
        isCompleted: false,
        loading: false,
        qa_shutter: action.payload.shutters,
        qa_script: action.payload.qa_script,
        survey: action.payload.survey,
        analysis: action.payload.analysis,
      };
    case "GET_QA_SCRIPT_COMPLETED":
      return {
        ...state,
        isCompleted: true,
        loading: false,
      };
    case "GET_QA_SCRIPT_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "POST_SELECTED_ANSWERS":
      return {
        ...state,
        loading: true,
        selectedAnswers: [],
      };
    case "POST_SELECTED_ANSWERS_SUCCESS":
      return {
        ...state,
        loading: false,
        selectedAnswers: action.payload,
      };
    case "POST_SELECTED_ANSWERS_FAILED":
      return {
        ...state,
        loading: false,
      };
    case "SUBMIT_SELECTED_ANSWERS":
      return {
        ...state,
        selectedAnswers: action.payload,
      };
    default:
      return state;
  }
};
