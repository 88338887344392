import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  background-color: #0f172a;
  color: #fff;
  main {
    flex: 1 0 auto;
    width: 100%;
    max-width: 1400px;
    margin: 34px auto 47px auto;
  }
`;
