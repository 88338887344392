import React, { memo } from "react";
import ReactLoading from "react-loading";
import { Container } from "./styles";

const Loading = () => {
  return (
    <Container>
      <ReactLoading
        type={"spinningBubbles"}
        color="#10b981"
        className="spinner"
      />
      <p>Loading...</p>
    </Container>
  );
};
export default memo(Loading);
