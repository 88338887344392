/* eslint-disable react/display-name */
import React from "react";
import Theme from "theme/global";
import Navigation from "routes/Navigation";
import UserProvider from "providers/UserProvider";
import AnalysisProvider from "providers/AnalysisProvider";
import QAProvider from "providers/QAProvider";
import KPIProvider from "providers/KPIProvider";
import DashboardProvider from "providers/DashboardProvider";

export default () => (
  <UserProvider>
    <AnalysisProvider>
      <QAProvider>
        <DashboardProvider>
          <KPIProvider>
            <Theme />
            <Navigation />
          </KPIProvider>
        </DashboardProvider>
      </QAProvider>
    </AnalysisProvider>
  </UserProvider>
);
