import React from "react";
import ReactDOM from "react-dom";
import App from "routes/App";
import { LocaleContextProvider } from "helpers/LocaleContext";
import { BrowserRouter } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

ReactDOM.render(
  <BrowserRouter basename={process.env.PUBLIC_URL}>
    <LocaleContextProvider>
      <ToastProvider>
        <App />
      </ToastProvider>
    </LocaleContextProvider>
  </BrowserRouter>,
  document.getElementById("root")
);
