/* eslint-disable react/prop-types */
import React, { createContext, useReducer, useContext } from "react";
import DashboardReducer from "reducers/DashboardReducer";

const DashboardContext = createContext();
const DashboardDispatchContext = createContext();

export default ({ children }) => {
  const [dashboardState, dispatch] = useReducer(DashboardReducer, {});

  return (
    <DashboardDispatchContext.Provider
      value={{
        dispatch,
      }}
    >
      <DashboardContext.Provider
        value={{
          dashboardState,
        }}
      >
        {children}
      </DashboardContext.Provider>
    </DashboardDispatchContext.Provider>
  );
};

export const useDashboard = () => useContext(DashboardContext);
export const useDispatchDashboard = () => useContext(DashboardDispatchContext);
