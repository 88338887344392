/* eslint-disable react/prop-types */
import React, { memo } from "react";
import { Container } from "./styles";

const Layout = ({ children }) => {
  return (
    <Container>
      <main>{children}</main>
    </Container>
  );
};

export default memo(Layout);
