/* eslint-disable react/prop-types */
import React, { createContext, useReducer, useContext } from "react";
import QAReducer from "reducers/QAReducer";

const QAContext = createContext();
const QADispatchContext = createContext();

export default ({ children }) => {
  const [QAState, dispatch] = useReducer(QAReducer, {});

  return (
    <QADispatchContext.Provider
      value={{
        dispatch,
      }}
    >
      <QAContext.Provider
        value={{
          QAState,
        }}
      >
        {children}
      </QAContext.Provider>
    </QADispatchContext.Provider>
  );
};

export const useQA = () => useContext(QAContext);
export const useDispatchQA = () => useContext(QADispatchContext);
