import styled from "styled-components";

export const Container = styled.div`
  display: grid;
  justify-content: center;
  height: 100%;
  margin: auto;
  p {
    font-family: Inter;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.42px;
    text-align: center;
    color: #eef2ff;
  }
  .spinner {
    margin: auto;
    margin-top: 85px;
  }
`;
