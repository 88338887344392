const initialState = {
  isLoggedIn: false,
  loading: false,
  analyseKPIData: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case "ANALYSE_KPI_LOADING":
      return {
        ...state,
        loading: true,
      };
    case "ANALYSE_KPI_SUCCESS":
      return {
        ...state,
        loading: false,
        analyseKPIData: action.payload,
      };
    case "ANALYSE_KPI_FAILED":
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
