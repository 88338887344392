/* eslint-disable react/prop-types */
import React, { createContext, useReducer, useContext } from "react";
import AnalysisReducer from "reducers/AnalysisReducer";

const AnalysisContext = createContext();
const AnalysisDispatchContext = createContext();

export default ({ children }) => {
  const [analysisState, dispatch] = useReducer(AnalysisReducer, {});

  return (
    <AnalysisDispatchContext.Provider
      value={{
        dispatch,
      }}
    >
      <AnalysisContext.Provider
        value={{
          analysisState,
        }}
      >
        {children}
      </AnalysisContext.Provider>
    </AnalysisDispatchContext.Provider>
  );
};

export const useAnalysis = () => useContext(AnalysisContext);
export const useDispatchAnalysis = () => useContext(AnalysisDispatchContext);
