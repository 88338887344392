/* eslint-disable react/prop-types */
import React, { createContext, useReducer, useContext } from "react";
import KPIReducer from "reducers/KPIReducer";

const KPIContext = createContext();
const KPIDispatchContext = createContext();

export default ({ children }) => {
  const [KPIState, dispatch] = useReducer(KPIReducer, {});

  return (
    <KPIDispatchContext.Provider
      value={{
        dispatch,
      }}
    >
      <KPIContext.Provider
        value={{
          KPIState,
        }}
      >
        {children}
      </KPIContext.Provider>
    </KPIDispatchContext.Provider>
  );
};

export const useKPI = () => useContext(KPIContext);
export const useDispatchKPI = () => useContext(KPIDispatchContext);
